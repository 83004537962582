import React from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom'; // Import NavLink
import { Link } from 'react-router-dom';

const Navigation: React.FC = () => {
  return (
    <div className="bg-gray-800 p-4">
      <div className="flex justify-between items-center h-10">
        <motion.h2
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="text-white text-xl font-semibold"
        >
          <Link to="/" className="no-underline text-white">
            vsharika
          </Link>
        </motion.h2>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="flex gap-4"
        >
          <NavLink
            to="/"
            className={({ isActive }) => isActive ? "text-white font-bold no-underline" : "text-gray-300 no-underline"}
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) => isActive ? "text-white font-bold no-underline" : "text-gray-300 no-underline"}
          >
            About Me
          </NavLink>
          <NavLink
            to="/projects"
            className={({ isActive }) => isActive ? "text-white font-bold no-underline" : "text-gray-300 no-underline"}
          >
            Projects
          </NavLink>
          <NavLink
            to="/resume"
            className={({ isActive }) => isActive ? "text-white font-bold no-underline" : "text-gray-300 no-underline"}
          >
            Resume
          </NavLink>
        </motion.div>
      </div>
    </div>
  );
};

export default Navigation;
