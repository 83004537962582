import sfuLogo from "../assets/images/logos/SFU_block_colour_rgb.png";
import ctcLogo from "../assets/images/logos/canadian-tire-logo-1.svg";

const workExperiences = [
    {
      title: "Service Desk Technician",
      period: "May 2023 – present",
      company: "Simon Fraser University",
      location: "Burnaby, British Columbia",
      logo: sfuLogo,
      tasks: [
        "Acted as the first point of contact for clients seeking IT support, providing comprehensive customer service, and ensuring client satisfaction.",
        "Assisted clients in resolving support issues related to IT services offered by SFU, including user accounts, printing, network connectivity, email and calendaring, and technology equipment bookings.",
        "Troubleshot computer, software, mobile device, network, and operating system issues, offering effective solutions and resolutions.",
        "Logged, prioritized, assigned, and documented incidents and client requests, actively working towards their resolution.",
        "Provided information, guidance, and assistance to clients in the use of software applications, hardware, and technology services.",
      ],
    },
    {
      title: "Security Analyst IAM",
      period: "September – December 2022",
      company: "Canadian Tire Corporation",
      location: "Toronto, Ontario (Remote)",
      logo: ctcLogo,
      tasks: [
        "Modified, deleted, or added new users to the active directory and provided or removed access for employees using Microsoft Azure based on Service Now requests.",
        "Developed the login component of a web application using HTML, CSS, and Bootstrap.",
        "Developed a python script to filter and sort excel worksheets to acquire the required data efficiently using Python’s Pandas library.",
        "Documented and analyzed the Service Now forms owned by my manager and derived solutions to simplify the forms.",
        "Collaborated with my fellow Co-op students using the Jira board to fulfill the given tasks effectively in an agile environment.",
      ],
    },
  ];

  export default workExperiences;