import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import styled, { keyframes } from "styled-components";

const jumpAnimation = keyframes`
  15% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(-9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(-18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(-9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
`;

const shadowAnimation = keyframes`
  0%, 100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
`;

const Loader = styled.div`
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;

  &:before {
    content: "";
    width: 48px;
    height: 5px;
    background: #1976d250; // Correct color format, added transparency
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: ${shadowAnimation} 0.5s linear infinite;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: #1976d2; // Primary blue color for the loader
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    animation: ${jumpAnimation} 0.5s linear infinite;
  }
`;

const Resume: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const googleDrivePdfUrl =
    "https://drive.google.com/file/d/14rcfBRs5p-zfmXw5nQ0xIYB30hXfKek7/preview";
  const downloadUrl =
    "https://drive.google.com/uc?export=download&id=14rcfBRs5p-zfmXw5nQ0xIYB30hXfKek7";

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500); // Properly clear the timeout
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{ position: "relative", paddingTop: "20px", textAlign: "center" }}
    >
      {loading ? (
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Changed alignContent to alignItems for proper centering
          height: "calc(90vh - 50px)",
        }}>
        <Loader />
        </div>
      ) : (
        <>
          <div
            style={{
              overflow: "hidden",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              border: "1px solid #ddd",
              height: "calc(90vh - 100px)",
              margin: "auto",
              maxWidth: "800px",
            }}
          >
            <iframe
              src={googleDrivePdfUrl}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              title="Resume"
              onLoad={() => setLoading(false)}
            ></iframe>
          </div>
          <Button
            variant="contained"
            color="primary"
            href={downloadUrl}
            startIcon={<DownloadForOfflineIcon />}
            style={{ marginTop: "20px", borderRadius: "0.5rem" }}
          >
            Download
          </Button>
        </>
      )}
    </div>
  );
};

export default Resume;
