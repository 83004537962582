import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  useTheme,
  useScrollTrigger,
  Zoom,
  Fab,
  Avatar,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GitHubIcon from "@mui/icons-material/GitHub";
import projects from "../data/projects";

const ProjectPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(1),
  borderRadius: "0.5rem",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  position: "relative",
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  "&:hover": {
    color: theme.palette.primary.dark,
    transition: "color 0.3s",
  },
}));

const ProjectsPage: React.FC = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("All");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100, // Trigger the button to appear after scrolling down 100px
  });

  // Function to change the active tab
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  // Filter projects based on the active tab
  const filteredProjects = projects.filter(
    (project) => activeTab === "All" || project.category === activeTab
  );

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <div className="flex flex-col w-full">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold text-primary-500">Projects</h1>
        </motion.div>
        {/* Navigation Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
          >
            <StyledTab label="All" value="All" />
            <StyledTab label="Web" value="Web" />
            <StyledTab label="Android" value="Android" />
            <StyledTab label="Python" value="Python" />
            <StyledTab label="Java" value="Java" />
            <StyledTab label="C++" value="C++" />
          </Tabs>
        </Box>
        <Grid container spacing={4} sx={{ mt: 2 }}>
          {filteredProjects.map((project, index) => (
            <Grid item xs={12} key={index}>
              {/* Wrap ProjectPaper with motion.div and apply variants */}
              <motion.div
                variants={variants}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.2 }} // Delay based on item index
              >
                <ProjectPaper>
                  {/* Logo floating to the left inside a circle */}
                  <Box
                    sx={{
                      position: "absolute",
                      left: theme.spacing(-4), // Adjust as needed
                      top: "0%",
                      transform: "translateY(-50%)",
                      width: theme.spacing(8), // Adjust size as needed
                      height: theme.spacing(8), // Adjust size as needed
                      borderRadius: "50%",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white", // Adjust based on your theme
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {/* Use Avatar or img tag for the logo */}
                    <Avatar
                      src={project.techLogo} // Path to your logo image
                      alt={project.technology[0]}
                      sx={{ width: "60%", height: "60%" }} // Adjust based on your needs
                    />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="h5">{project.title}</Typography>
                      <Typography variant="subtitle1">
                        {project.period}
                      </Typography>
                      <Typography paragraph>{project.description}</Typography>
                      <Box>
                        <Typography variant="h6">Features:</Typography>
                        <ul>
                          {project.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box>
                        <Typography variant="h6">Technology Stack:</Typography>
                        <ul>
                          {project.technology.map((tech, index) => (
                            <li key={index}>{tech}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start", // Align the button to the left side
                          gap: 2,
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="contained" // Use 'contained' for a solid background
                          sx={{
                            backgroundColor: "#fff", // Initial background color is white
                            color: "#000", // Initial text/icon color is black
                            textTransform: "none", // Keep the button text as is without uppercase transformation
                            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                            height: "40px",
                            borderRadius: "0.5rem",
                            marginTop: "10px",
                            ":hover": {
                              backgroundColor: "#000", // Background turns black on hover
                              color: "#fff", // Text/icon color turns white on hover
                            },
                          }}
                          startIcon={<GitHubIcon sx={{ color: "inherit" }} />} // Icon color inherits from the Button's color property
                          href={project.githubURL}
                          target="_blank" // Open in a new tab
                          rel="noopener noreferrer" // Security for opening links in a new tab
                        >
                          View on GitHub
                        </Button>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow
                          borderRadius: "0.5rem", // Soft rounded corners
                          width: "100%", // Takes full width of its container
                          height: "400px", // Fixed height, adjust as needed
                          backgroundSize: "cover", // Cover the container's size
                          backgroundPosition: "center", // Center the background image
                          backgroundImage: `url(${project.projectBanner})`, // Set image as background
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden", // Ensures the container doesn't show overflow
                        }}
                      >
                        {/* No img tag needed since we're using background image */}
                      </Box>
                    </Grid>
                  </Grid>
                </ProjectPaper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
        <Zoom in={trigger}>
          <Box
            sx={{
              position: "fixed",
              bottom: theme.spacing(3),
              right: theme.spacing(3),
            }}
          >
            <Fab
              color="primary"
              size="small"
              aria-label="scroll back to top"
              onClick={scrollToTop}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Box>
        </Zoom>
      </div>
    </Container>
  );
};

export default ProjectsPage;
