import reactLogo from "../assets/images/logos/react-2.svg";
import angularLogo from "../assets/images/logos/angular-icon-1.svg";
import htmlLogo from "../assets/images/logos/html-1.svg";
import cssLogo from "../assets/images/logos/css-3.svg";
import bootstrapLogo from "../assets/images/logos/bootstrap-5-1.svg";
import javaScriptLogo from "../assets/images/logos/logo-javascript.svg";
import typeScriptLogo from "../assets/images/logos/typescript.svg";
import jQueryLogo from "../assets/images/logos/jquery-4.svg";
import ejsLogo from "../assets/images/logos/icons8-ejs.svg";
import domLogo from "../assets/images/logos/DOM.png";
import nodeJSLogo from "../assets/images/logos/nodejs-icon.svg";
import expressJSLogo from "../assets/images/logos/express-109.svg";
import restAPILogo from "../assets/images/logos/API.png";
import sqlLogo from "../assets/images/logos/SQL.png";
import postgreSQLLogo from "../assets/images/logos/postgresql.svg";
import firebaseLogo from "../assets/images/logos/firebase-2.svg";
import passportJSLogo from "../assets/images/logos/passport.png";
import oAuthLogo from "../assets/images/logos/oauth.svg";
import npmLogo from "../assets/images/logos/npm-square-red-1.svg";
import gitHubLogo from "../assets/images/logos/github-icon-1.svg";
import azureLogo from "../assets/images/logos/azure-2.svg";
import serviceNowLogo from "../assets/images/logos/servicenow-2.svg";
import pythonLogo from "../assets/images/logos/python-5.svg";
import javaLogo from "../assets/images/logos/java-14.svg";
import cLogo from "../assets/images/logos/c.svg";
import kotlinLogo from "../assets/images/logos/kotlin-1.svg";
import kerasLogo from "../assets/images/logos/keras.png";
import tensorFlowLogo from "../assets/images/logos/tensorflow-2.svg";

import {
  Web,
  Storage,
  Security,
  CloudQueue,
  Code,
  Cloud,
} from "@mui/icons-material";

const skills = [
  {
    category: "Frontend Development",
    icon: Web,
    skills: [
      { name: "React", img: reactLogo },
      { name: "Angular", img: angularLogo },
      { name: "HTML", img: htmlLogo },
      { name: "CSS", img: cssLogo },
      { name: "Bootstrap", img: bootstrapLogo },
      {
        name: "JavaScript",
        img: javaScriptLogo,
      },
      { name: "TypeScript", img: typeScriptLogo },
      { name: "jQuery", img: jQueryLogo },
      {
        name: "Embedded JavaScript (EJS)",
        img: ejsLogo,
      },
      {
        name: "Document Object Model (DOM)",
        img: domLogo,
      },
    ],
  },
  {
    category: "Backend Development",
    icon: Storage,
    skills: [
      { name: "Node.js", img: nodeJSLogo },
      { name: "Express.js", img: expressJSLogo },
      { name: "REST APIs", img: restAPILogo },
    ],
  },
  {
    category: "Databases",
    icon: CloudQueue,
    skills: [
      { name: "SQL", img: sqlLogo },
      { name: "PostgreSQL", img: postgreSQLLogo },
      {
        name: "Firestore database",
        img: firebaseLogo,
      },
    ],
  },
  {
    category: "Authorization",
    icon: Security,
    skills: [
      { name: "Passport.js", img: passportJSLogo },
      { name: "OAuth", img: oAuthLogo },
      {
        name: "Firebase authentication",
        img: firebaseLogo,
      },
    ],
  },
  {
    category: "DevOps and Version Control",
    icon: Cloud,
    skills: [
      { name: "npm", img: npmLogo },
      { name: "GitHub", img: gitHubLogo },
      { name: "Microsoft Azure", img: azureLogo },
      { name: "ServiceNow", img: serviceNowLogo },
    ],
  },
  {
    category: "Languages and Libraries",
    icon: Code,
    skills: [
      { name: "Python", img: pythonLogo },
      { name: "Java", img: javaLogo },
      { name: "C++", img: cLogo },
      { name: "Kotlin", img: kotlinLogo },
      { name: "Keras", img: kerasLogo },
      { name: "TensorFlow", img: tensorFlowLogo },
    ],
  },
];

export default skills;