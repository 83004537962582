import React from "react";
import { motion } from "framer-motion"; // Import Framer Motion
import profilePhoto from "../assets/images/profile_photo.jpg"; // Ensure correct path

const HomePage: React.FC = () => {
  // Animation variants for the text content
  const textAnimation = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  // Animation variants for the profile photo
  const photoAnimation = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.5, duration: 0.5 },
    },
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-200 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto w-full py-12 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-center">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={textAnimation}
          className="lg:col-span-2 space-y-6 p-8"
        >
          <h1 className="text-4xl font-bold text-gray-900">
            Welcome! 👋 I'm Vishavjit Singh Harika
          </h1>
          <p className="text-lg text-gray-700">
            I am a Computing Science professional based in Vancouver, BC, with a rich background in web
            development and IT support. My expertise lies in bridging front-end technologies like React and Angular
            with back-end systems using Node.js and SQL, driven by a passion for leveraging technology to solve
            complex challenges. With a proven track record at prestigious institutions like Simon Fraser University
            and Canadian Tire Corporation, I have honed my skills in delivering impactful IT solutions and fostering
            technical growth. As I pursue my BSc in Computing Science from Simon Fraser University, I am eager to
            contribute innovative solutions and continuous technical advancement to dynamic teams.
          </p>
        </motion.div>
        <div className="flex justify-center lg:justify-end">
          <motion.img
            src={profilePhoto}
            alt="Profile of Vishavjit S. Harika"
            className="w-64 h-64 lg:w-96 lg:h-96 rounded-xl object-cover shadow-xl"
            initial="hidden"
            animate="visible"
            variants={photoAnimation}
          />
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default HomePage;
