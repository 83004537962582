import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  useTheme,
  useScrollTrigger,
  Zoom,
  Fab,
  IconButton,
  styled,
} from "@mui/material";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { motion } from "framer-motion";
import "../styles/AboutMePage.css";
import memoji1 from "../assets/images/memoji-1.png";
import sfuBlackLogo from "../assets/images/logos/SFU_vertical_black.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import skills from "../data/skills";
import workExperiences from "../data/work-experience";

const AboutMePage: React.FC = () => {
  const theme = useTheme();
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const socialLinks = {
    linkedIn: "https://www.linkedin.com/in/vsharika",
    github: "https://github.com/vsharika1",
    email: "mailto:vsharika.work@gmail.com",
  };

  useEffect(() => {
    const grid = gridRef.current;
    if (grid) {
      const papers = grid.querySelectorAll(".MuiPaper-root");
      const heights = Array.from(papers).map((paper) => paper.clientHeight);
      setMaxHeight(Math.max(...heights));
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100, // Trigger the button to appear after scrolling down 100px
  });

  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const paperVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (custom: number) => ({
      opacity: 1,
      y: 0,
      transition: { delay: custom * 0.2 },
    }),
  };

  const iconStyle = (bgColor: string, borderColor: string) => ({
    color: borderColor,
    backgroundColor: "#fff",
    border: `1px solid ${borderColor}`,
    "&:hover": {
      color: "#fff",
      backgroundColor: bgColor,
      borderColor: bgColor,
    },
    mx: 1, // margin left and right
  });

  const LinkedInTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#0e76a8",
      color: "white",
    },
    [`& .MuiTooltip-arrow`]: {
      color: "#0e76a8",
    },
  }));

  const GitHubTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#333",
      color: "white",
    },
    [`& .MuiTooltip-arrow`]: {
      color: "#333",
    },
  }));

  const EmailTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#BB001B",
      color: "white",
    },
    [`& .MuiTooltip-arrow`]: {
      color: "#BB001B",
    },
  }));

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <div className="flex flex-col w-full">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={contentVariants}
          className="text-start mb-2"
        >
          <h1 className="text-3xl font-bold text-primary-500">About Me</h1>
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={contentVariants}
          className="bg-white shadow-md rounded-lg p-6 w-full"
        >
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="lg:col-span-2">
              <h2 className="text-2xl font-semibold mb-2">
                Vishavjit Singh Harika
              </h2>
              <p className="text-base mb-3">
                A versatile Computing Science professional based in Vancouver,
                BC, with robust experience in web development and IT support.
                Expertise spans across front-end technologies like React and
                Angular to back-end systems using Node.js and SQL. Passionate
                about leveraging technology to solve complex problems, with a
                proven track record in delivering impactful IT solutions and
                fostering technical growth.
              </p>
              <h3 className="text-xl mb-1">Connect with me:</h3>
              <div className="flex justify-start">
                <LinkedInTooltip title="LinkedIn" arrow>
                  <IconButton
                    href={socialLinks.linkedIn}
                    target="_blank"
                    sx={iconStyle("#0e76a8", "#0e76a8")}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </LinkedInTooltip>
                <GitHubTooltip title="GitHub" arrow>
                  <IconButton
                    href={socialLinks.github}
                    target="_blank"
                    sx={iconStyle("#333", "#333")}
                  >
                    <GitHubIcon />
                  </IconButton>
                </GitHubTooltip>
                <EmailTooltip title="Email" arrow>
                  <IconButton
                    href={socialLinks.email}
                    sx={iconStyle("#BB001B", "#BB001B")}
                  >
                    <EmailIcon />
                  </IconButton>
                </EmailTooltip>
              </div>
            </div>
            <div className="lg:col-span-1 flex justify-center items-center">
              <img
                src={memoji1}
                alt="Profile"
                className="max-w-xs rounded-lg"
              />
            </div>
          </div>
        </motion.div>
      </div>

      {/*Technical Skills*/}
      <div className="flex flex-col w-full my-4">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={contentVariants}
          className="mb-2"
        >
          <h1 className="text-3xl font-bold text-primary-500">Technical Skills</h1>
        </motion.div>
        <Grid container spacing={2} ref={gridRef}>
          {skills.map((skillCategory, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              component={motion.div}
              initial="hidden"
              animate="visible"
              variants={paperVariants}
              custom={index}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: maxHeight ? `${maxHeight}px` : "auto",
                  borderRadius: "0.5rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <skillCategory.icon sx={{ mr: 1 }} />
                  <Typography variant="h6">{skillCategory.category}</Typography>
                </Box>
                {skillCategory.skills.map((skill, skillIndex) => (
                  <Box
                    key={skillIndex}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <img
                      src={skill.img}
                      alt={skill.name}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                    <Typography>{skill.name}</Typography>
                  </Box>
                ))}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>

      {/*Work Experience*/}
      <div className="flex flex-col w-full my-4">
      <motion.div
          initial="hidden"
          animate="visible"
          variants={contentVariants}
          className="mb-2"
        >
          <h1 className="text-3xl font-bold text-primary-500">Work Experience</h1>
        </motion.div>

        <Grid container spacing={2}>
          {workExperiences.map((experience, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  transition: "all 0.3s ease", // Smooth transition for height and padding changes
                  borderRadius: "0.5rem",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item sm={12} lg={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                      }}
                    >
                      <img
                        src={experience.logo}
                        alt="Simon Fraser University Logo"
                        style={{
                          width: "50px",
                          height: "auto",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={12} lg={11}>
                    <Box
                      sx={{
                        display: "block",
                        alignItems: "center",
                      }}
                    >
                      <div id="title-period-container">
                        <Typography variant="h6">{experience.title}</Typography>
                        <Typography variant="h6">
                          {experience.period}
                        </Typography>
                      </div>
                      <div id="company-location-container">
                        <Typography variant="body1">
                          {experience.company}
                        </Typography>
                        <Typography variant="body1">
                          {experience.location}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </Grid>

                <Box
                  component="ul"
                  sx={{
                    listStyleType: "disc",
                    marginLeft: 3,
                    padding: 0,
                    marginTop: 2,
                  }}
                >
                  {experience.tasks.map((task, taskIndex) => (
                    <Box
                      component="li"
                      key={taskIndex}
                      sx={{ marginBottom: 1 }}
                    >
                      <Typography variant="body1">{task}</Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      
      {/*Education*/}
      <div className="flex flex-col w-full my-4">
      <motion.div
          initial="hidden"
          animate="visible"
          variants={contentVariants}
          className="mb-2"
        >
          <h1 className="text-3xl font-bold text-primary-500">Education</h1>
        </motion.div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, borderRadius: "0.5rem" }}>
              <Grid container spacing={2}>
                <Grid item sm={12} lg={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                    }}
                  >
                    <img
                      src={sfuBlackLogo}
                      alt="Simon Fraser University Logo"
                      style={{
                        width: "100px",
                        height: "auto",
                        marginRight: "8px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item sm={12} lg={10}>
                  <Box
                    sx={{
                      display: "block",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <div id="degree-container">
                      <Typography variant="h6">
                        BSc. in Computing Science
                      </Typography>
                      <Typography variant="h6">
                        September 2019 – April 2024
                      </Typography>
                    </div>
                    <div id="institution-container">
                      <Typography variant="body1">
                        Simon Fraser University
                      </Typography>
                      <Typography variant="body1">
                        Burnaby, British Columbia
                      </Typography>
                    </div>
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      Focused on Software Development, Machine Learning, and Web
                      Technologies.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>

      <Zoom in={trigger}>
        <Box
          sx={{
            position: "fixed",
            bottom: theme.spacing(3),
            right: theme.spacing(3),
          }}
        >
          <Fab
            color="primary"
            size="small"
            aria-label="scroll back to top"
            onClick={scrollToTop}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Zoom>
    </Container>
  );
};

export default AboutMePage;
