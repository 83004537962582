import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../components/HomePage';
import AboutMePage from '../components/AboutMePage';
import ProjectsPage from '../components/ProjectsPage';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Resume from '../components/Resume'

const AppRouter: React.FC = () => (
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutMePage />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path='/resume' element={<Resume />} />
    </Routes>
    <Footer />
  </Router>
);

export default AppRouter;
