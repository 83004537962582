import React from "react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-100 text-center text-gray-600 py-3 w-full left-0 bottom-0">
      <div className="max-w-7xl mx-auto">
        © {currentYear} Vishavjit Singh Harika. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
