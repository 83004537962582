import reactLogo from "../assets/images/logos/react-2.svg";
import androidLogo from "../assets/images/logos/android-circle.png";
import cLogo from "../assets/images/logos/c.svg";
import angularLogo from "../assets/images/logos/angular-icon-1.svg";
import javaScriptLogo from "../assets/images/logos/logo-javascript.svg";
import typeScriptLogo from "../assets/images/logos/typescript.svg";
import javaLogo from "../assets/images/logos/java-14.svg";
import pythonLogo from "../assets/images/logos/python-5.svg";
import fluentHandsBanner from "../assets/images/project-banners/fluenthands_app.png";
import histogramAppBanner from "../assets/images/project-banners/histogram_app.png";
import dogVsCatBanner from "../assets/images/project-banners/imageclassifier.png";
import nuisanceReportBanner from "../assets/images/project-banners/reporting_web_app_logo.png";
import pigMgtAppBanner from "../assets/images/project-banners/pig_management_app.png";
import myRunsBanner from "../assets/images/project-banners/myruns_app.png";
import recipeAppBanner from "../assets/images/project-banners/recipeStorageApp.png";
import keeperAppBanner from "../assets/images/project-banners/keeperApp.png";
import stressMeterBanner from "../assets/images/project-banners/stressMeter.png";
import exchangeBanner from "../assets/images/project-banners/sfuExchange.png";
import routeFinderBanner from "../assets/images/project-banners/routeFinder.png";
import dataAnalyserBanner from "../assets/images/project-banners/dataAnalyser.png";
import denyAndConquerBanner from "../assets/images/project-banners/denyAndConquer.png";
import shiftEaseBanner from "../assets/images/project-banners/shiftease.png";

const projects = [
  {
    title: "ShiftEase",
    period: "Mar 2024 - Apr 2024",
    description:
      "ShiftEase simplifies shift scheduling with a digital platform that enhances efficiency in the service industry.",
    features: [
      "Log in with secure access to manage and view schedules",
      "Create, assign, and update shifts based on real-time availability",
      "View and manage employee profiles and contact information",
      "Automatically generate optimized schedules using advanced algorithms",
      "Receive and manage shift assignments and updates directly",
    ],
    technology: [
      "React",
      "Remix",
      "Node.js",
      "PostgreSQL",
      "Auth0",
      "Google OR API",
    ],
    techLogo: reactLogo,
    category: "Web",
    projectBanner: shiftEaseBanner,
    githubURL: "https://github.com/vsharika1/ShiftEase.git",
  },
  {
    title: "Recipe App",
    period: "January 2024",
    description:
      "A web app for culinary enthusiasts to add, view, and manage recipes.",
    features: [
      "Add, view, and delete recipes",
      "Persistent storage with local storage",
    ],
    technology: ["React", "JavaScript", "Material UI"],
    techLogo: reactLogo,
    category: "Web",
    projectBanner: recipeAppBanner,
    githubURL: "https://github.com/vsharika1/recipe-app-v1.git",
  },
  {
    title: "Keeper - React App",
    period: "January 2024",
    description: "A note-taking app with features similar to Google Keep.",
    features: [
      "Note creation, display, deletion",
      "Responsive UI with Material-UI",
    ],
    technology: ["React", "JavaScript", "Material-UI"],
    techLogo: reactLogo,
    category: "Web",
    projectBanner: keeperAppBanner,
    githubURL: "https://github.com/vsharika1/keeper-app.git",
  },
  {
    title: "Nuisance Reporting Web App",
    period: "December 2023",
    description:
      "Enables reporting of disturbances with detailed nuisance reports.",
    features: [
      "Detailed nuisance reports submission",
      "Interactive map with incident details",
      "Dynamic data table for reports",
    ],
    technology: ["Angular", "TypeScript", "Leaflet Maps API"],
    techLogo: angularLogo,
    category: "Web",
    projectBanner: nuisanceReportBanner,
    githubURL: "https://github.com/vsharika1/nuisance-report-angular-app.git",
  },
  {
    title: "Fluent Hands",
    period: "November - December 2023",
    description:
      "A cutting-edge application leveraging machine learning to interpret hand gestures in real-time, enhancing communication accessibility. Utilizes the device's camera for detecting hand landmarks and classifying gestures.",
    features: [
      "Real-time interpretation of hand gestures using the device's front camera",
      "Integration with GoogleSample/MediaPipe for gesture recognition",
      "Ability to process live camera feeds, static images, and videos",
      "Customizable gesture recognition with personal task file support",
    ],
    technology: ["Machine Learning", "MediaPipe", "Android"],
    techLogo: androidLogo,
    category: "Android",
    projectBanner: fluentHandsBanner,
    githubURL: "https://github.com/vsharika1/fluent-hands.git",
  },
  {
    title: "MyRuns - Physical Activity Tracker",
    period: "September - November 2023",
    description:
      "Developed MyRuns Android application, designed to track and display physical activity.",
    features: [
      "User profile activity with personal data input and saving",
      "Room database for exercise entries logging, viewing history, and data management",
      "Real-time GPS tracking of activities with Google Maps",
      "Activity classifier integration for recognizing physical activities",
    ],
    technology: [
      "Android Development",
      "Kotlin",
      "Room Database",
      "Google Maps API",
    ],
    techLogo: androidLogo,
    category: "Android",
    projectBanner: myRunsBanner,
    githubURL: "https://github.com/vsharika1/my-runs.git",
  },
  {
    title: "Pig Management Application",
    period: "November 2023",
    description:
      "Developed a custom TypeScript application for efficiently managing pig inventory.",
    features: [
      "User-friendly interface for managing pig information",
      "Dynamic forms for data accuracy and enhanced user experience",
      "Data persistence using Local Storage",
    ],
    technology: ["TypeScript", "HTML5", "CSS", "Bootstrap (Framework)"],
    techLogo: typeScriptLogo,
    category: "Web",
    projectBanner: pigMgtAppBanner,
    githubURL: "https://github.com/vsharika1/pig-management-web-app.git",
  },
  {
    title: "Stress Meter App",
    period: "October 2023",
    description:
      "A mobile application designed to track and manage stress levels in real-time, utilizing interactive assessments and historical data visualization to identify stress patterns.",
    features: [
      "Interactive Stress Assessment with curated images",
      "Historical Data Visualization for identifying stress patterns",
      "Customizable UI with modern design standards",
      "Sound and Vibration Feedback for enhanced interaction",
    ],
    technology: ["Android Development", "Kotlin", "MVVM Architecture"],
    techLogo: androidLogo,
    category: "Android",
    projectBanner: stressMeterBanner,
    githubURL: "https://github.com/vsharika1/stressmeter.git",
  },
  {
    title: "Dynamic Grade Distribution Histogram Tool",
    period: "October 2023",
    description:
      "Client-side tool using HTML, CSS, and JavaScript for visualizing student grades distribution.",
    features: [
      "Real-time grade distribution histograms",
      "Interactive grade cutoff adjustments without reloading the page",
    ],
    technology: ["JavaScript", "HTML5", "CSS"],
    techLogo: javaScriptLogo,
    category: "Web",
    projectBanner: histogramAppBanner,
    githubURL: "https://github.com/vsharika1/histogram-tool-web-app.git",
  },
  {
    title: "Dog vs Cat Image Classifier",
    period: "January - April 2023",
    description:
      "Designed and implemented a neural network to classify images as either cats or dogs. This involved preprocessing images, designing the neural network architecture, and training and evaluating the model.",
    features: [
      "Preprocessed and prepared image dataset for training and testing",
      "Designed and implemented the neural network architecture using Keras and TensorFlow",
      "Trained the image classifier model and adjusted hyperparameters for optimal performance",
      "Evaluated the model's performance on the testing dataset",
      "Deployed the trained model for prediction on new, unseen images",
      "Conducted iterative improvements to enhance accuracy and generalization",
    ],
    technology: ["Python", "Keras", "TensorFlow"],
    techLogo: pythonLogo,
    category: "Python",
    projectBanner: dogVsCatBanner,
    githubURL: "https://github.com/vsharika1/dog-vs-cat-image-classifier.git",
  },
  {
    title: "Deny And Conquer",
    period: "January - April 2023",
    description:
      "A multiplayer grid-based game where players compete to claim cells. Features client-server architecture for real-time interaction and game state management.",
    features: [
      "Client-server multiplayer interaction",
      "Real-time cell locking, claiming, and unlocking",
      "TCP socket communication for consistent game state",
      "Customizable player roles and game dynamics",
    ],
    technology: ["Java", "TCP Sockets", "Multi-threading"],
    techLogo: javaLogo,
    category: "Java",
    projectBanner: denyAndConquerBanner,
    githubURL: "https://github.com/vsharika1/deny-and-conquer.git",
  },
  {
    title: "Data Analyzer",
    period: "January - April 2022",
    description:
      "Developed a Python-based program for data analysis, visual representation, and correlation determination using p-value tests, enhancing research and study capabilities.",
    features: [
      "Data sorting and organization for precise analysis",
      "Visual representation of data using graphs and tables",
      "P-value test implementation for determining data set relationships",
    ],
    technology: ["Python", "matplotlib", "seaborn"],
    techLogo: pythonLogo,
    category: "Python",
    projectBanner: dataAnalyserBanner,
    githubURL: "https://github.com/vsharika1/data-analyser.git",
  },
  {
    title: "Exchange",
    period: "May - August 2021",
    description:
      "An Android application to promote a circular economy at SFU, facilitating the exchange of goods within the university community. Developed with Firebase integration for user authentication and data management.",
    features: [
      "User authentication and data management with Firebase",
      "Interactive UI for easy navigation and item exchange",
      "Collaborative project development and vision realization",
    ],
    technology: [
      "Android Studio",
      "Firebase Authentication",
      "Firestore Database",
    ],
    techLogo: androidLogo,
    category: "Android",
    projectBanner: exchangeBanner,
    githubURL: "https://github.com/vsharika1/exchange-marketplace.git",
  },
  {
    title: "Cost-Effective Route Finder",
    period: "November - December 2020",
    description:
      "A C++ program to compute the most cost-effective route between two locations, utilizing algorithms for precise computation and console-based interaction for user inputs.",
    features: [
      "Algorithmic computation of cost-effective routes",
      "Console-based user interaction for route display",
      "Extensive testing with various case scenarios for reliability",
    ],
    technology: ["C++"],
    techLogo: cLogo,
    category: "C++",
    projectBanner: routeFinderBanner,
    githubURL: "https://github.com/vsharika1/cost-effective-route-finder.git",
  },
];

export default projects;
