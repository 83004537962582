import React from "react";
import "../styles/App.css";
import AppRouter from "../routes/AppRouter";
import { styled } from "@mui/material/styles";

const MainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "#f5f5f5",
});

function App() {
  return (
    <MainContainer>
        <AppRouter />
    </MainContainer>
  );
}

export default App;